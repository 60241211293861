import React from "react";
import { styled } from "@mui/material/styles";
import { Box, Rating } from "@mui/material";
import PropTypes from "prop-types";

const MainCard = styled("div")(({ theme, baby, bgc, bc, showmore, min }) => ({
  minHeight: !baby ? (showmore ? "470px" : "470px") : "none",
  maxHeight: !baby ? (showmore ? "830px" : "470px") : "none",
  height: "fit-content",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  borderRadius: "10px",
  backgroundColor: baby ? theme.palette.appcolors.tertiaryColor10 : bgc,
  border: `1px solid ${baby ? theme.palette.appcolors.tertiaryColor18 : bc}`,
  padding: baby ? "20px 34px" : "43px 34px",
  boxShadow: "0px 6px 12px 0px #00000029",
  transition: "max-height 1s ease-in-out, min-height 1s ease-in-out",
  [theme.breakpoints.up(2400)]: {
    minHeight: !baby && "500px",
  },
  [theme.breakpoints.down(1280)]: {
    padding: "20px",
    minHeight: !baby ? (showmore ? "350px" : "340px") : "none",
    maxHeight: !baby ? (showmore ? "600px" : "340px") : "none",
  },
  [theme.breakpoints.down(800)]: {
    padding: "10px 20px",
    minHeight: !baby ? (showmore ? "240px" : "240px") : "none",
    maxHeight: !baby ? (showmore ? "400px" : "240px") : "none",
  },
  [theme.breakpoints.down(600)]: {
    maxHeight: !baby ? (showmore ? "500px" : "240px") : "none",
  },
  [theme.breakpoints.down(400)]: {
    maxHeight: !baby ? (showmore ? "600px" : "240px") : "none",
  },
}));

const MainCardHeaderContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  gap: "0.5rem",
}));

const MainCardProfilePicture = styled("img")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  width: "75px",
  height: "75px",
  border: `2px solid ${theme.palette.appcolors.tertiaryColor10}`,
  [theme.breakpoints.down(1280)]: {
    width: "50px",
    height: "50px",
  },
}));

const MainCardProfileContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  minWidth: "80px",
}));

const MainCardTitle = styled("p")(({ theme, point, baby }) => ({
  fontWeight: 600,
  color: point
    ? theme.palette.appcolors.primaryColor
    : theme.palette.appcolors.black,
  [theme.breakpoints.up(2400)]: {
    fontSize: "18px",
  },
  [theme.breakpoints.down(1280)]: {
    fontSize: "10px",
  },
  [theme.breakpoints.down(600)]: {
    fontFamily: baby ? "Poppins" : "Figtree",
    fontSize: baby ? (point ? "12px" : "10px") : "12px",
    fontWeight: baby ? (point ? 700 : 600) : 400,
  },
}));

const MainCardSubtitle = styled("p")(({ theme }) => ({
  fontSize: "10px",
  fontFamily: "Figtree",
  marginTop: "-2px",
  color: theme.palette.appcolors.black,
  [theme.breakpoints.up(2400)]: {
    fontSize: "14px",
  },
  [theme.breakpoints.down(600)]: {
    fontFamily: "Poppins",
  },
}));

const MainCardRating = styled(Rating)(({ theme, baby }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  padding: "1rem 0rem",
  paddingBottom: !baby && "2rem",
  fontSize: baby ? "0.9rem" : "1.3rem",
  gap: "0.2rem",
  color: theme.palette.appcolors.tertiaryColor11,
  [theme.breakpoints.up(2400)]: {
    paddingBottom: !baby && "3rem",
  },
  [theme.breakpoints.down(1280)]: {
    padding: "0.5rem 0rem",
  },
}));

const MainCardBodyContainer = styled("div")(
  ({ theme, showmore, baby, linebreak }) => ({
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: baby ? null : !showmore && !linebreak && "5",
    WebkitBoxOrient: "vertical",
    marginBottom: "1rem",
    [theme.breakpoints.down(800)]: {
      WebkitLineClamp: baby ? null : !showmore && !linebreak && "3",
      marginBottom: "0.4rem",
      padding: "0rem 1rem",
    },
  })
);

const MainCardReviewText = styled("p")(({ theme, baby }) => ({
  textAlign: "center",
  fontSize: baby && "14px",
  color: theme.palette.appcolors.black,
  [theme.breakpoints.up(2400)]: {
    fontSize: baby ? "15px" : "18px",
  },
  [theme.breakpoints.down(1280)]: {
    fontFamily: !baby && "Figtree",
    fontSize: baby ? "10px" : "12px",
  },
  [theme.breakpoints.down(600)]: {
    fontFamily: "Figtree",
    fontSize: baby ? "14px" : "16px",
  },
}));

const ShowMoreLink = styled("p")(({ theme }) => ({
  color: theme.palette.appcolors.black,
  fontSize: "14px",
  fontWeight: 600,
  cursor: "pointer",
  padding: "0.5rem 0rem",
  [theme.breakpoints.down(1280)]: {
    fontSize: "10px",
  },
  [theme.breakpoints.down(600)]: {
    padding: "0rem",
  },
}));

const QuoteIcon = styled("svg")(({ theme, baby }) => ({
  width: baby ? 20 : 40,
  height: baby ? 20 : 40,
  [theme.breakpoints.down(1280)]: {
    width: baby ? 15 : 30,
    height: baby ? 15 : 30,
  },
}));

export const mainCard = (
  key,
  img,
  name,
  points,
  score,
  review,
  theme,
  width,
  bgc,
  bc,
  showMore,
  setShowMore,
  linebreak,
  setLinebreak
) => {
  const handleShowMore = (key) => {
    setShowMore((prevShowMore) => ({
      ...prevShowMore,
      [key]: !prevShowMore[key],
    }));
  };

  const handleLineBreak = (key) => {
    const timer = setTimeout(() => {
      setLinebreak((prev) => ({
        ...prev,
        [key]: !prev[key],
      }));
    }, 888);
    return () => clearTimeout(timer);
  };

  return (
    <MainCard key={key} bgc={bgc} bc={bc} showmore={showMore[key]}>
      <MainCardHeaderContainer>
        <MainCardProfilePicture src={img} alt="pic" />
        <MainCardProfileContainer>
          <MainCardTitle>{name}</MainCardTitle>
          {points === 0 ? null : (
            <>
              <MainCardTitle point>{points}</MainCardTitle>
              <MainCardSubtitle>Uppnådd poäng</MainCardSubtitle>
            </>
          )}
        </MainCardProfileContainer>
      </MainCardHeaderContainer>
      <MainCardRating value={score} readOnly />
      <MainCardBodyContainer
        showmore={showMore[key]}
        linebreak={linebreak[key]}
      >
        <MainCardReviewText>{review}</MainCardReviewText>
      </MainCardBodyContainer>
      {review.length < (width < 600 ? 100 : 200) ? null : (
        <ShowMoreLink
          onClick={() => {
            handleShowMore(key), handleLineBreak(key);
          }}
        >
          Visa {showMore[key] ? "mindre" : "mer"}
        </ShowMoreLink>
      )}
      <QuoteIcon
        xmlns="http://www.w3.org/2000/svg"
        fill={theme.palette.appcolors.black}
        viewBox="0 0 16 16"
      >
        <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388q0-.527.062-1.054.093-.558.31-.992t.559-.683q.34-.279.868-.279V3q-.868 0-1.52.372a3.3 3.3 0 0 0-1.085.992 4.9 4.9 0 0 0-.62 1.458A7.7 7.7 0 0 0 9 7.558V11a1 1 0 0 0 1 1zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612q0-.527.062-1.054.094-.558.31-.992.217-.434.559-.683.34-.279.868-.279V3q-.868 0-1.52.372a3.3 3.3 0 0 0-1.085.992 4.9 4.9 0 0 0-.62 1.458A7.7 7.7 0 0 0 3 7.558V11a1 1 0 0 0 1 1z" />
      </QuoteIcon>
    </MainCard>
  );
};

export const babyCard = (key, img, name, points, score, review, theme) => {
  return (
    <MainCard baby key={key}>
      <MainCardRating baby value={score} readOnly />
      <MainCardBodyContainer baby>
        <MainCardReviewText baby>{review}</MainCardReviewText>
      </MainCardBodyContainer>
      <QuoteIcon
        baby
        xmlns="http://www.w3.org/2000/svg"
        fill={theme.palette.appcolors.black}
        viewBox="0 0 16 16"
      >
        <path d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388q0-.527.062-1.054.093-.558.31-.992t.559-.683q.34-.279.868-.279V3q-.868 0-1.52.372a3.3 3.3 0 0 0-1.085.992 4.9 4.9 0 0 0-.62 1.458A7.7 7.7 0 0 0 9 7.558V11a1 1 0 0 0 1 1zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612q0-.527.062-1.054.094-.558.31-.992.217-.434.559-.683.34-.279.868-.279V3q-.868 0-1.52.372a3.3 3.3 0 0 0-1.085.992 4.9 4.9 0 0 0-.62 1.458A7.7 7.7 0 0 0 3 7.558V11a1 1 0 0 0 1 1z" />
      </QuoteIcon>
      <MainCardHeaderContainer>
        <MainCardProfilePicture src={img} alt="pic" />
        <MainCardProfileContainer>
          <MainCardTitle baby>{name}</MainCardTitle>
          {points === 0 ? null : (
            <>
              <MainCardTitle baby point>
                {points}
              </MainCardTitle>
              <MainCardSubtitle>Uppnådd poäng</MainCardSubtitle>
            </>
          )}
        </MainCardProfileContainer>
      </MainCardHeaderContainer>
    </MainCard>
  );
};

MainCard.propTypes = {
  baby: PropTypes.bool,
};
MainCardRating.propTypes = {
  baby: PropTypes.bool,
};
MainCardBodyContainer.propTypes = {
  baby: PropTypes.bool,
};
MainCardReviewText.propTypes = {
  baby: PropTypes.bool,
};
QuoteIcon.propTypes = {
  baby: PropTypes.bool,
};
